/* Your variables */
$gutter: 20px;

  /* Work card */
$work-card-items: 4;
$work-card-rows: ceil($work-card-items / 2);

$card-min-height: 150px;
$card-max-height: 400px;
$card-width: 450px;
$inner-margin: 15px;
$number-size: 35px;
$stagger: 180px;
$outer-margin: 90px;
$marker-size: 45px;

$container-height: $work-card-rows * ($card-max-height + $outer-margin) + $stagger;
$container-width: $card-width * 2 + $outer-margin * 3;
$marker-dist: $card-width + $outer-margin/2 - $marker-size/2;

$animation-delay: 1.2s;

  /* Colors */
$background: #f7f7f7;
$accent: #00aaaa;
$purple: #ce43eb;
$blue: #4d92eb;
$grey: #9d9d9d;
$black: #262626;
$light-black: #636363;
$text: #7a7a7a;
$green: #76bc21;
$white: #fff;
$timeline: #bdbdbd;
$red: #e31235;